import m from 'mithril';
import 'slick-carousel/slick/slick';
import Database from '../utils/database.class';
import $ from 'jquery';
class migrantGallery  {
    constructor() { 
  
    } 

    oncreate() {
      this.initMouse();
      $('.slickgallery').on('init', (slick) => {
        this.initMouse();
      });
        $('.slickgallery').slick({
            arrows:true,
            slidesToShow: 4,
            centerPadding: '10%',
            centerMode:true,
              
            slidesToScroll: 1,            
            responsive: [
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    centerPadding: '80px',
                  }
                },
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    centerPadding: '60px',
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '40px',
                  }
                }
              ]
        }); 

        $('.slickgallery').on('wheel', (function(e) {
          if (e.originalEvent.deltaX < 0 && e.originalEvent.deltaY == 0) $(this).slick('slickPrev');
          if (e.originalEvent.deltaX > 0 && e.originalEvent.deltaY == 0) $(this).slick('slickNext');

        }));

        
        //$('.slickgallery').slick('slickSetOption', 'slidesToScroll', 4, true);


          
    } 

    onupdate() {
      this.initMouse()
    }
    initMouse () {
      $("#magicPointer").remove();
      let options = {
           "cursorOuter": "disable",
           "hoverEffect": "text-hover",
           "hoverItemMove": false,
           "defaultCursor": false,
           "outerWidth": 30,
           "outerHeight": 30
      };
      magicMouse(options);

  }
    view () {
        
        return [m("div", {class:"headline"}, m.trust(Database.getInterface().galleryheadline)),
              m("div", {class:"migrantgallery"}, [
                  m("div", [
                      
                      m("div", {class:"row slickgallery"}, [
                          
                          Database.getMigrants().map( (elem, index) => {
                              return m(m.route.Link, {class:"migrant", href:"/migrant/"+elem.id}, [
                                  m("img", {src: "images/migrants_sw/"+elem.image, class:m.id}),
                                  m("div", {class:"description"}, m.trust(elem.description)),
                                  m("div", {class:"diagonal-box"}),
                                  m("div", {class:"info"}, [
                                      m("div", {class:"name"}, elem.name)
                                  ])
                              ])
                          })  


                      ])
                  ])
              ])  ]      
    }
}


export default migrantGallery;