import m from 'mithril';
import Database from '../utils/database.class';

class Header  {
    constructor() {
        
    }

    view () {
        return m("header", [

           m("div", {class:"diagonal-box"}),
           
           m("div", {class:"container"}, [

                m("div", {class:"row"}, [
                    m("div", {class:"col-lg-4 col-md-5 col-12 titles order-lg-1 order-md-1 order-2"}, [
                        m(m.route.Link, {href:"/", class:"title"}, Database.getInterface().sitetitle),
                        m("div", {class:"subtitle"}, m.trust(Database.getInterface().sitesubtitle)),
                    ]),
                    
                    
                    m("div", {class:"logos col-lg-5 col-md-5 col-8 offset-md-0 order-1 order-lg-2"}, [
                        
                        Database.getInterface().logos.map( (elem, index) => {
                            return m("div", {class:"logo id_"+index}, [
                                m("img", {src: elem.logo, class:"logo_"+index})
                            ])
                        })                

                    ]),
                    m("div", {class:"about col-lg-1 col-md-1 col-4 offset-lg-2 order-1 order-lg-2"}, [
                        
                        m(m.route.Link, {href:"/about"}, [
                            m("img", {src: "images/Pfeil_About_01.png", class:"arrow"})
                        ],Database.getInterface().aboutlink)
                    ])
                ])
            ])
        ])        
    }
}


export default Header;