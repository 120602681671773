import Database from './database.class';
let DBLoaded = new Event("DBLoaded");

class Preloader {


    constructor() {
        this.initDatabaseListeners();

        this.databasesNr = 0;
        Database.loadMigrants();
        Database.loadScholars();
        Database.loadInterface();
        Database.loadAbout();
        Database.loadFilmmakers();
        Database.loadImprint();
        //this.initMagicMouse();
    }

    initDatabaseListeners () {
        document.addEventListener('migrantsLoaded', (e) => {
            console.log("migrants", Database.getMigrants());
            
            this.databaseLoadingComplete();
        })
        document.addEventListener('scholarsLoaded', (e) => {
            console.log("scholars", Database.getScholars());
            
            this.databaseLoadingComplete();
        })
        document.addEventListener('interfaceLoaded', (e) => {
            console.log("interface", Database.getInterface());
            
            this.databaseLoadingComplete();
        })

        document.addEventListener('aboutLoaded', (e) => {
            console.log("about", Database.getAbout());
            
            this.databaseLoadingComplete();
        })

        document.addEventListener('imprintLoaded', (e) => {
            console.log("imprint", Database.getImprint());
            
            this.databaseLoadingComplete();
        })
        document.addEventListener('filmmakersLoaded', (e) => {
            console.log("filmmakers", Database.getFilmmakers());
            
            this.databaseLoadingComplete();
        })
        
        document.addEventListener('DBLoaded', (e) => {
            console.log("ALL DB IS LOADED");
            
        })
    }

    databaseLoadingComplete () {
        this.databasesNr += 1;
        if (this.databasesNr >= 6) {
            Database.setIsLoaded(true);
            document.dispatchEvent(DBLoaded);
        }
    }


    view () {

    }
}

export default Preloader;