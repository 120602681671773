import m from 'mithril';
import Database from '../utils/database.class';

class Footer  {
    constructor() {
        
    }

    view () {
        return m("footer", [
            m("div", {class:"links"}, [
             //   m(m.route.Link, {href:"/imprint"}, Database.getInterface().imprint)
            ])
        ])        
    }
}


export default Footer;