import m from 'mithril';
import Database from '../utils/database.class';
import $ from 'jquery';
class aboutHeader  {
    constructor() {
        $.ajax(
            {
                url: "images/map.svg",
                dataType: 'html', 
                type: 'GET',
                success: function(data)
                {         
                  //  console.log (data);
                    $(".imagemap").prepend(data);

                    $('circle').hover (function() { 
                        $( this ).siblings( ".st2" ).fadeIn();
                    }, function() {
                        $( this ).siblings( ".st2" ).fadeOut();
                    }) 

                    $('circle').click(function() {
                        let id = $(this).parent().attr('id');
                        let hash = id.split('_x5F_')[0];
                        let hashID = id.split('_x5F_')[1];

                        if (hash == "scholar") {
                            let migrant = Database.getMigrants().filter(obj => {if (obj.scholarid == hashID) return obj;})[0];
                            hashID = migrant.id;
                        }
                        
                        
                        m.route.set("/migrant/" + hashID);
                    })
                }
            });
    }

    view () {
        return m("header", [

           m("div", {class:"container"}, [

                m("div", {class:"row"}, [
                    m("div", {class:"col-lg-4 col-md-5 col-12 titles order-lg-1 order-md-1 order-2"}, [
                        m(m.route.Link, {href:"/", class:"title"}, Database.getInterface().sitetitle),
                        m("div", {class:"subtitle"}, m.trust(Database.getInterface().sitesubtitle)),
                    ]),
                    
                    
                    m("div", {class:"logos col-lg-5 col-md-5 col-8 offset-md-0 order-1 order-lg-2"}, [
                        
                        Database.getInterface().logos.map( (elem, index) => {
                            return m("div", {class:"logo id_"+index}, [
                                m("img", {src: elem.logo, class:"logo_"+index})
                            ])
                        })                

                    ]),
                    m("div", {class:"about col-lg-1 col-md-1 col-4 offset-lg-2 order-1 order-lg-2"}, [
                        m(m.route.Link, {href:"/"}, [
                            m("div", {class:"close"})
                        ],Database.getInterface().aboutlink)
                    ])
                ])
            ]),


            m("div", {class:"imagemap"}),
        ])        
    }
}


export default aboutHeader;