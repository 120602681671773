import m from "mithril";
import Preloader from '../utils/preloader.class';
import Database from '../utils/database.class';
import contentSection from "../components/contentSection.class";
import $ from 'jquery';
import FooterLarge from "../components/footerLarge.class";
import SidebarScholar from "../components/sidebarScholar.class";
//import dotNavigation from "../components/dotNavigation.class";

let scholarID;
class Scholar {
    constructor() {
        $("html").removeClass();
        $("html").addClass("scholar");
        $('#magicPointer').removeClass();
        $('#magicPointer span').remove();
        scholarID = m.route.param("key");
        if (!Database.isLoaded()) this.preloader = new Preloader();

        gtag('set', 'page_path', "scholar/"+scholarID);
        gtag('event', 'page_view');
    }

    oncreate() {
        $("html, body").animate({ scrollTop: 0 }, "fast");
    }
 
    view () {
        if (!Database.isLoaded()) return; // make sure the DB is loaded
        let scholar = Database.getScholars().filter(obj => {if (obj.id == scholarID) return obj;})[0];
        let migrant = Database.getMigrants().filter(obj => {if (obj.scholarid == scholarID) return obj;})[0];
        if (migrant == undefined) migrant = Database.getMigrants().filter(obj => {if (obj.scholarid_second == scholarID) return obj;})[0];
       
        let scholarImg = (scholar.id == 2) ? Database.getFilmmakers()[3].image : scholar.image;
        let related_stories_array = scholar.related_stories.split(",");


        return [
                m("div", {class:"content row col-12 g-0"}, [
                    // SCHOLAR HEADER
                    m("div", {class:"scholar-header g-0 row"}, [
                        m("div", {class:"info col-lg-3"}, [
                            m(SidebarScholar),
                            m("div", {class:"col-sm-8"}, [
                                m("div", {class:"name"}, (scholar.id == 2) ? Database.getFilmmakers()[3].name : scholar.name),
                                m("div", {class:"description"}, m.trust((scholar.id == 2) ? Database.getFilmmakers()[3].description : scholar.description )),
                            ])
                            
                        ]),
                        

                        m(m.route.Link, {href:"/migrant/"+migrant.id,class:"image col-9"}, [
                            m("div", {class:"diagonal-box-left"}),
                            m("img", {src: "images/scholars_text/"+ scholarImg })
                        ])
                    ]),




                    // BLOCK QUOTE & PDF
                    m("div", {class:"scholar-blockquote g-0"}, [
                        m("div", {class:"container col-12"}, [
                            m("div", {class:"row"}, [
                                m("div", {class:"downloadpdf col-lg-3 col-md-3 col-sm-12"}, [
                                    m("a", {class:"pdfurl", target:"_blank", href: (scholar.id == 2) ? "pdf/AraiaTamayoAlvarez_MigrantLivesPandemicTimes.pdf" : "pdf/"+scholar.pdf}, Database.getInterface().download_pdf ),
                                ]),
                                m("div", {class:"col-lg-9 col-md-9 col-sm-12"}, [
                                    m("div", {class:"block"}, m.trust(scholar.long_description_headline)),
                                    m("div", {class:"blockquote"}, m.trust(scholar.quote_headline))
                                ])
                            ])  
                        ])                        
                    ]), 





                    // MAIN CONTENT 
                    m("div", {class:"maincontent row g-lg-0 g-md-0 g-sm-1"}, [ 
                        //m(dotNavigation, {sections:scholar.maincontent}),
                        // MAIN PARTS 
                        m(contentSection, {type:"scholar", content:scholar, part: "part1"}),
                        m(contentSection, {type:"scholar", content:scholar, part: "part2"}),
                        m(contentSection, {type:"scholar", content:scholar, part: "part3"}),
                        m(contentSection, {type:"scholar", content:scholar, part: "part4"}),
                        m(contentSection, {type:"scholar", content:scholar, part: "part5"}),

                        // FURTHER READING                 
                        m("section", {id:"part5"}, [

                            m("div", {class:"container col-12"}, [

                                m("div", {class:"part row"}, [                               
                                    m("div", {class:"title col-lg-3 col-md-7 col-sm-12"}, Database.getInterface().further_reading_headline),
                                    m("div", {class:"col-lg-9 col-md-5 col-sm-12"}, [
                                        scholar.further_reading.map( (data, index) => {                            
                                            return [
                                                m("div", {class:"link"}, m("a", {href:data.url, target:"_blank"}, data.title))
                                            ]
                                        })
                                    ]) 

                                ])



                            ])
                            
                        ]),
                    ]),

                    





                    // END BLOCK QUOTE
                    m("div", {class:"scholar-migrant scholar-blockquote container col-10"}, [

                        m("div", {class:"row"}, [
                            m(m.route.Link, {class:"row related-migrant", href:"/migrant/"+migrant.id}, [
                                
                                
                                m("div", {class:"col-lg-4 col-md-4 col-sm-12 imageblock"}, [
                                    m("img", {src: "images/migrants_related/"+ migrant.image, class:migrant.id}),
                                    m("div", {class:"description"}, m.trust(migrant.description)),
                                    m("div", {class:"info"}, [
                                        m("div", {class:"name"}, migrant.name)
                                    ])
                                ]),
                               

                                m("div", {class:"blockquote col-lg-6 col-md-6 offset-lg-1 offset-md-1 col-sm-12"}, [
                                    m("div", {class:"quote"}, [
                                        m("div", m.trust(migrant.quote)),
                                        m("div", {class:"quotesource"}, m.trust(migrant.quote_source)),
    
                                    ]),
                                    
                                ])

                            ]),
                            
                        ])
                    ]),







                    // RELATED STORIES
                    m("div", {class:"related-stories container col-10"}, [
                        m("div", {class:"row"}, [
                            m("div", {class:"headline"}, Database.getInterface().relatedStories_headline),
                            related_stories_array.map( (elem, index) => {
                                
                                let migrant = Database.getMigrants().filter(obj => {if (obj.id == elem) return obj;})[0];
                                return [
                                    m(m.route.Link, {class:"col-md-4 col-sm-12 related-migrant", href:"/migrant/"+migrant.id}, [
                                            m("img", {src: "images/migrants_related/"+ migrant.image, class:migrant.id}),
                                            m("div", {class:"description"}, m.trust(migrant.description)),
                                            m("div", {class:"info"}, [
                                                m("div", {class:"name"}, migrant.name)
                                            ])
                                    ])
                                    
                                ]
                            }),
                        ]),
                        
                    ]),


                    // FOOTER 
                    m(FooterLarge)
                ])
            
        ]
        
    }
}

export default Scholar;
