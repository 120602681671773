import m from 'mithril';
import Database from '../utils/database.class';
class SidebarScholar  {
    constructor() {
        
    }

    view (vnode) {
        let next_story;
        let back_story;
        next_story = ( parseInt(m.route.param("key")) ) >= Database.getMigrants().length ? 1 : parseInt(m.route.param("key"))+1 ;

        
        for (let i = 0; i < Database.getMigrants().length; i++) {
            if (Database.getMigrants()[i].scholarid == m.route.param("key")) {
                back_story = Database.getMigrants()[i].id;
                next_story = back_story+1;
                
                if (next_story > Database.getMigrants().length) next_story = 1;
            } 
        }
 
        return m("sidebar", [
            m("div", {class:"links"}, [
                m(m.route.Link, {href:"/", class:"left"}, [
                    m("img", {src: "images/allstories_migrant.svg", class:"arrow left all"})
                ], Database.getInterface().sidebar_allstories),

                m(m.route.Link, {href:"/migrant/"+next_story, class:"left"}, [
                    m("img", {src: "images/Pfeil_next_story_01.png", class:"arrow left"})
                ], Database.getInterface().sidebar_nextstory),

                m(m.route.Link, {href:"/migrant/"+back_story, class:"left"}, [
                    m("img", {src: "images/previous_story.png", class:"arrow left"})
                ], Database.getInterface().sidebar_backtovideo)
            ])
        ])        
    } 
}


export default SidebarScholar;