import m from "mithril";
import SidebarMigrant from '../components/sidebarMigrant.class';
import Preloader from '../utils/preloader.class';
import Database from '../utils/database.class';
import $ from 'jquery';


let migrantID, migrant;
let scholarID, scholar;
let migrantplayer, scholarplayer;
class Migrant {
    constructor() {
        $("html").removeClass();
        $("html").addClass("migrant");

        migrantID = m.route.param("key");
        if (!Database.isLoaded()) this.preloader = new Preloader();
        
        gtag('set', 'page_path', "migrant/"+migrantID);
        gtag('event', 'page_view');
    }

    oncreate() {
         this.controller();
        
    }
    onupdate () { 
        migrantID = m.route.param("key"); 
        this.controller();
    }

    controller() {
        
        this.initMigrantPlayer();
        this.initScholarPlayer();    
        
        this.initMouse();
        this.initClickEvents();  
        this.initSocialIcons();
        
        if (Modernizr.touchevents) $('.hide').removeClass("hide");

    }

    initSocialIcons () {
        var buttonsContainer = $('.socials');
        new Shariff(buttonsContainer, { 
            services: ['facebook', 'twitter'],            
        });

        $('.socials ul').prepend("<li class='embed'>" + Database.getInterface().embed_code + "</li>");
        $('.socials').prepend("<span class='copied'>" + Database.getInterface().copied_embed_code + "</span>");
        $('.embed').click(() => {
            this.copyStringToClipboard();
            $('.copied').addClass('active');
            setTimeout(() => {
                $('.copied').removeClass("active");
            }, 500);
        })
    }

    copyStringToClipboard () { 
        
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = '<iframe width="560" height="315" src="https://www.youtube.com/embed/' + migrant.video +'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);
    }


    initMigrantPlayer() {
        
        try {
            if (migrantplayer) migrantplayer.dispose();
            //console.log ("MPLAYER", migrantplayer);
            migrantplayer = videojs("migrantvideo-" + migrantID, {
                preload: true,
                autoplay: false,
                controls: true,
                fill:true,
                controlBar: { 
                    'pictureInPictureToggle': false,
                    'muteToggle': false,
                    // children: [
                    //     "playToggle",
                    //     "volumeMenuButton",
                    //     "durationDisplay",
                    //     "timeDivider",
                    //     "currentTimeDisplay",
                    //     "progressControl",
                    //     "remainingTimeDisplay",
                    //     "fullscreenToggle",
                    //     "SubtitlesButton"
                    // ]
                }
            }); 

            // migrantplayer.on("play", function () {
            //     console.log ("play");
            // });
            // migrantplayer.on("error", function (e) {
            //     console.log ("error", e);
            // });
            // migrantplayer.on("abort", function (e) {
            //     console.log ("abort", e);
            // });
            // migrantplayer.on("loadstart", function (e) {
            //     console.log ("loadstarted", e);
            //     migrantplayer.play();
            //     migrantplayer.pause();
            // });
            // migrantplayer.on("loadeddata", function (e) {
            //     console.log ("loadeddata", e);
            // });
            // migrantplayer.on("canplay", function (e) {
            //     console.log ("canplay", e);
            // });

            // migrantplayer.on("waiting", function (e) {
            //     console.log ("waiting", e);
            // });
            
        } catch (error) {
            console.log ("DOM NOT READY");
            return; // DOM IS NOT READY YET
        }
    }


    initScholarPlayer() {
        
        try {
            if (scholarplayer) scholarplayer.dispose();
            console.log ("SCHOLAR PLAYER", scholarplayer);
            scholarplayer = videojs("scholarvideo-" + scholarID, {
                preload: true,
                autoplay: false,
                controls: true,
                fill:true, 
                controlBar: { 
                    'pictureInPictureToggle': false,
                    'fullscreenToggle': false,
                    'muteToggle': false,
                    // children: [
                    //     //    "playToggle",
                    //         "volumeMenuButton",
                    //         "durationDisplay",
                    //         "timeDivider",
                    //         "currentTimeDisplay",
                    //         "progressControl",
                    //         "remainingTimeDisplay",
                    //     //    "fullscreenToggle",
                    //         "SubtitlesButton"
                    //     ]
                }
            });

            scholarplayer.on("pause", function () {
                //console.log ("PAUSE");
                scholarplayer.pause();
            });
            scholarplayer.on("play", function () {
             //   console.log ("PLAY");
                //scholarplayer.play();
            });
            
            
        } catch (error) {
            return; // DOM IS NOT READY YET
        }
    }



    initClickEvents () {
        this.closeVideo();
        this.watchScholar();
        this.toggleMigrant();
    }


    toggleMigrant () {
        $(".migrantpage").unbind(); 
        $(".migrantpage").click((e) => {
            e.preventDefault();

            // if the migrant is minimized
            if ($(".migrantpage").hasClass("minimize")) {
                $(".migrantpage").removeClass("col-lg-3");
                $(".migrantpage").removeClass("minimize");
                $(".migrantpage").addClass("col-lg-7");
                $(".migrantpage .info").fadeIn(.2);


                $(".scholarteaser").removeClass("col-lg-8");
                $(".scholarteaser").removeClass("maximize");
                $(".scholarteaser").addClass("col-lg-4");
                $(".scholarvideo").removeClass("show");
                $(".scholarteaser .scholarinfo").fadeIn(.2);
                $(".scholarteaser .scholarinfo_video").fadeOut(.2);
                $('.quote_long').fadeOut(.2);
                $('.quote_d_h').fadeOut(.2);
                $('.quote').fadeOut(.2);
                scholarplayer.pause();
                
            } else {
                $('.scholarteaser').fadeOut(); 
                $('.migrantpage').fadeOut();
                
                
                
                setTimeout(() => {
                    $('.migrantvideo').fadeIn("fast", () => {
                        migrantplayer.play();
                    });
                }, 500); 
                
            }


            

        })
    }


    watchScholar () {
        $(".scholar-watch").unbind();
        $(".scholar-watch").click ( (e) => {
            e.preventDefault();
            //$(".migrantpage").addClass("minimize");
            $(".migrantpage").removeClass("col-lg-7");
            $(".migrantpage").addClass("col-lg-3");
            $(".migrantpage").addClass("minimize");

        
            $(".scholarteaser").removeClass("col-lg-4");
            $(".scholarteaser").addClass("col-lg-8");
            if (window.innerWidth > 768) {
                $(".scholarteaser").addClass("maximize");
                $('.quote_long').fadeIn(.2);
                $('.quote_d_h').fadeIn(.2);
                $('.quote').fadeIn(.2);
                if (window.innerWidth > 768) $(".scholarteaser .scholarinfo").fadeOut(.2);
                if (window.innerWidth > 768) $(".scholarteaser .scholarinfo_video").fadeIn(.2);
            }
            

            $(".scholarvideo").addClass("show");
           
            
            scholarplayer.play();
        })
    }


    closeVideo () {
        $(".migrantvideo .close").unbind(); 
        $(".migrantvideo .close").click ((e) => {
            e.preventDefault();
            migrantplayer.pause();
            $('.scholarteaser').fadeIn();    
            $('.migrantvideo').fadeOut();
            $('.migrantpage').fadeIn();
        });
    }

    initMouse () {
        $("#magicPointer").remove();
        let options = {
             "cursorOuter": "disable",
             "hoverEffect": "text-hover",
             "hoverItemMove": false,
             "defaultCursor": false,
             "outerWidth": 30,
             "outerHeight": 30
        };
        magicMouse(options);

    }
    view() {

        if (!Database.isLoaded()) return; // make sure the DB is loaded
        migrant = Database.getMigrants().filter(obj => {if (obj.id == migrantID) return obj;})[0];
        scholar = Database.getScholars().filter(obj => {if (obj.id == migrant.scholarid) return obj;})[0];
        scholarID = scholar.id;
        
        console.log ("SID", Database.getFilmmakers()[3].name, scholar.name); 

        return [
         
               m("div", {class:"row g-0"}, [

                    m(SidebarMigrant), // col-1

                    // MIGRANT
                    m("a", {href: "#",class: "migrantpage magic-hover col-lg-7 col-md-12",hovertext: Database.getInterface().watch}, [
                        m("div", {class: "image"}, [
                            m("img", {src: "images/migrants_sw/"+ migrant.image}),
                            m("div", {class:"diagonal-box-left"}),
                            m("div", {class:"diagonal-box-right"})
                        ]),
                        m("div", {class: "info"}, [
                            m("div", {class: "map"}, [
                                m("img", {src: "images/maps/"+ migrant.mapimage})
                            ]),

                            m("div", {class: "meta"}, [
                                m("div", {class: "name"}, migrant.name),
                                m("div", {class: "description"}, m.trust(migrant.description))
                            ])

                        ]),


                        m("div", {class:"play-button-outer hide"}, [
                            m("div", {class:"play-button"})
                        ]),

                    ]),
 
                    

                    // MIGRANT VIDEO
                    m("div", {class: "migrantvideo col-lg-11 offset-lg-1 col-md-11 offset-md-1"}, [
                        m("div", {class:"socials"}),
                        m("div", {class:"diagonal-box-left"}),
                        m("div", {class:"diagonal-box-right"}), 
                    //    m("div", {class:"diagonal-box-top"}),
                        m("a", {class:"close magic-hover", hovertext: Database.getInterface().close}),

                        m("video", {
                            id: "migrantvideo-" + migrantID,
                            class: "video-js vjs-theme-city vjs-fill",
                            controls: false,
                            autoplay: false,
                            width: window.innerWidth - 100,
                            height: window.innerHeight - 200,
                            "data-setup": '{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "https://www.youtube.com/watch?v=' + migrant.video + '"}], "youtube": { "iv_load_policy": 3, "modestbranding":1, "rel":0 } }'
                        }, [
                            
                            m("track", {src: 'srt/'+migrant.video_subtitles, kind:'subtitles', srclang: 'en', label: 'English', default:true})
                        ]),

 
                        m("div", {class:"info"}, [
                            m("div", {class: "meta"}, [
                                m("div", {class: "name"}, migrant.name),
                                m("div", {class: "description"}, m.trust(migrant.description))
                            ]),
                          //  m("div", {class:"quote col-lg-6 col-md-6 col-sm-12"}, migrant.quote)
                            m(m.route.Link, {class:"magic-hover scholar-read", href:"/scholar/"+scholar.id, hovertext: Database.getInterface().read}, [
                                m("div", {class: "beyond_title"}, Database.getInterface().headline_beyondthestory),
                                m("img", {src: "images/Pfeil_read_beyond_01.png", class:"arrow"})
                            ])
                        ])
                        
                    ]),
 
                    // SCHOLAR
                    m("div", {class: "scholarteaser col-lg-4 col-md-12 offset-lg-1"}, [
                        
                        m("div", {class:"scholar-watch"}, [
                            m("div", {class:"scholarinfo"}, [
                                m("div", {class: "behind_title"}, m.trust(Database.getInterface().migrant_start_scholar_headline)),
                                m("div", {class: "name"}, (scholar.id == 2) ? Database.getFilmmakers()[3].name : scholar.name),
                                m("div", {class: "description"}, m.trust((scholar.id == 2) ? Database.getFilmmakers()[3].description : scholar.description)),
                            ]),
                            
                            m("div", {class:"scholarinfo_video"}, [
                                m("div", {class: "behind_title"}, m.trust(Database.getInterface().migrant_start_scholar_headline_2)),
                                m("div", {class: "name"}, (scholar.id == 2) ? Database.getFilmmakers()[3].name : scholar.name),
                                //m("div", {class: "description"}, m.trust(scholar.description)),
                            ]),
                            m("div", {class: "image", }, [
                                m("img", {class:"magic-hover", src: (scholar.id == 2) ? "images/scholars_sm/"+Database.getFilmmakers()[3].image : "images/scholars_sm/"+scholar.image, hovertext: Database.getInterface().watch}),
                                m("div", {class:"diagonal-box"}),


                                m("div", {class:"play-button-outer hide"}, [
                                    m("div", {class:"play-button"})
                                ]),

                                m("div", {class:"scholarvideo"}, [
                                    
                                    // m("div", {class:"scholartitle"}, [
                                    //     m("div", {class:"heading"}, Dat),
                                    //     m("div", {class:"subheading"}, "F"),
                                    // ]), 
                                    
                                    m("video", {
                                        id: "scholarvideo-" + scholar.id,
                                        class: "video-js vjs-theme-city",
                                        controls: false,
                                        autoplay: false,    
                                        "data-setup": '{ "techOrder": ["youtube"], "sources": [{ "type": "video/youtube", "src": "https://www.youtube.com/watch?v=' + scholar.video + '"}], "youtube": { "iv_load_policy": 3, "modestbranding":1, "rel":0 } }'
                                    }, [
                                        m("track", {src: 'srt/'+scholar.video_subtitles, kind:'subtitles', srclang: 'en', label: 'English', default:true})
                                    ])
                                ])
                                
                            ]),
                            
                        ]),
                        
                        m(m.route.Link, {class:"magic-hover scholar-read", href:"/scholar/"+scholar.id, hovertext: Database.getInterface().read}, [
                            m("div", {class: "quote_d_h"}, m.trust(scholar.long_description_headline)),
                          //  m("div", {class: "quote"}, m.trust(scholar.quote_headline)),
                            m("div", {class: "quote_long"}, m.trust(scholar.teaser_text)), 
                            m("div", {class: "beyond_title"}, Database.getInterface().headline_beyondthestory),
                            m("div", {class: "beyond_teaser"},[
                                m("div", [
                                    m("span", [
                                        m("div", {class:"cta"}, m.trust(scholar.read_cta)),
                                        m("img", {src: "images/Pfeil_read_beyond_01.png", class:"arrow"})
                                    ]
                                    ),
                                    
                                ]),
                                
                            ]),
                            
                            
                        ])
                        
                    ])
                ])
        ]
    }
}

export default Migrant;