import m from "mithril";
import FooterLarge from "../components/footerLarge.class";
import Preloader from '../utils/preloader.class';
import Database from '../utils/database.class';
import contentSection from "../components/contentSection.class";
import $ from 'jquery';
import Header from '../components/header.class';


class Imprint {

    constructor() {
        $("html").removeClass();
        $("html").addClass("imprint");

        if ( !Database.isLoaded() ) this.preloader = new Preloader();

        gtag('set', 'page_path', "imprint");
        gtag('event', 'page_view');
    }


    view() {
        if (!Database.isLoaded()) return; // make sure the DB is loaded
        let about = Database.getImprint()[0];
        

        return [
            m(Header),
            m("div", {class:"maincontent"}, [
                m(contentSection, {type:"imprint", content:about, part: "part1"}), 
                m(contentSection, {type:"imprint", content:about, part: "part2"}), 
                m(contentSection, {type:"imprint", content:about, part: "part3"}), 
                m(contentSection, {type:"imprint", content:about, part: "part4"}), 
            ]),

            
            
            m(FooterLarge)
            
        ]
    }
}

export default Imprint;