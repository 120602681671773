import m from 'mithril';

class contentSection  {
    constructor() { 
        
    }

    view (vnode) {
        
        let content = vnode.attrs.content;
        let part = vnode.attrs.part;
        let type = vnode.attrs.type; 
        
        //let scholarOffset = (type == "scholar") ? " offset-1 col-11": ""; 
        if (content.maincontent[part] == undefined) return;
        let hideClass = "";
        if (content.maincontent[part].quote == "" || content.maincontent[part].quote == undefined)  hideClass = "hide";
        
        return [
            m("section", {id:part}, [  
                m("div", {class:"container col-12"}, [ 
                    m("div", {class:"part part1 row"}, [
                        m("div", {class:"col-lg-3 col-md-4 col-sm-12"}, [
                            m("div", {class:"title"}, content.maincontent[part].title),
                            m("div", {class:"quote " + hideClass}, content.maincontent[part].quote)
                        ]),
                        m("div", {class:"description col-lg-8 offset-lg-1 col-md-8 col-sm-12"}, m.trust(content.maincontent[part].description)),
                    ])
                ]),
                
                m("div", {class: (content.maincontent[part].images.length > 1) ? "row images" : "row images"   }, [
                    content.maincontent[part].images.map( (image,index) => {
                        let imagenr = content.maincontent[part].images.length;
                        let column_class = (imagenr == 1) ? "col-12" : "col-lg-6 col-md-6 col-sm-12";
                        let hide = (image.description == "" || image.description == undefined) ? "hide" : "";
                        if (image.url == undefined) return;
                        return [
                            
                            m("div", {class:"image " + column_class}, [
                                m("img", {class:(image.url == "4-1_Natalie_Still_09_a.jpg" || image.url == "2-1_Mary_Still_07_a.jpg" || image.url == "4-1_Araia_Still_08a.jpg" || image.url == "4-2_Julia_Still_08_a.jpg" || image.url == "4-1_Julia_Still_06_a.jpg") ? "contain" : "", src:"images/scholars_text/sections/"+image.url}),
                                
                                m("div", {class:"description " + hide}, [
                                    m("div", {class:"diagonal"}),
                                    m("div", {class:"desc"}, image.description),
                                    
                                ])
                            ])
                        ]
                    })
                ]),
                
            ])
        ]    
    }
}


export default contentSection;