import m from 'mithril';
import Database from '../utils/database.class';

class FooterLarge  {
    constructor() {
        
    }

    view () {

        return m("footer", {class:"large"}, [


            m("div", {class:"container"}, [

                m("div", {class:"row g-0"}, [
                    m("div", {class:"col-lg-3 col-12 heading"}, Database.getInterface().footer.headline),
                    m("div", {class:"col-lg-4 col-6 info"}, m.trust(Database.getInterface().footer.info)),
                    
                    
                    
                    
                    m("div", {class:"col-lg-5 col-6 socials"}, [
                        m("ul", {class:"theme-color orientation-horizontal col-2 ryerson"}, [
                            m("li", {class:"shariff-button youtube col-6"}, [
                                m("a",{href:Database.getInterface().footer.social_r.youtube, target:"_blank"},[m("span", {class:"fa fa-youtube"})])
                            ]),

                            m("li", {class:"shariff-button linkedin col-6"}, [
                                m("a",{href:Database.getInterface().footer.social_r.linkedin, target:"_blank"},[m("span", {class:"fa fa-linkedin"})])
                            ]),

                            m("li", {class:"shariff-button twitter col-6 clear"}, [
                                m("a",{href:Database.getInterface().footer.social_r.twitter, target:"_blank"},[m("span", {class:"fa fa-twitter"})])
                            ]),

                            m("li", {class:"shariff-button facebook col-6"}, [
                                m("a",{href:Database.getInterface().footer.social_r.facebook, target:"_blank"},[m("span", {class:"fa fa-facebook"})])
                            ])
                        ]),


                        m("ul", {class:"theme-color orientation-horizontal col-2 mm"}, [
                            m("li", {class:"shariff-button youtube col-6"}, [
                                m("a",{href:Database.getInterface().footer.social_mm.youtube, target:"_blank"},[m("span", {class:"fa fa-youtube"})])
                            ]),

                            m("li", {class:"shariff-button instagram col-6"}, [
                                m("a",{href:Database.getInterface().footer.social_mm.instagram, target:"_blank"},[m("span", {class:"fa fa-instagram"})])
                            ]),

                            m("li", {class:"shariff-button twitter col-6 clear"}, [
                                m("a",{href:Database.getInterface().footer.social_mm.twitter, target:"_blank"},[m("span", {class:"fa fa-twitter"})])
                            ]),

                            m("li", {class:"shariff-button facebook col-6"}, [
                                m("a",{href:Database.getInterface().footer.social_mm.facebook, target:"_blank"},[m("span", {class:"fa fa-facebook"})])
                            ])
                        ])
                    ]),



                    
                    //m(m.route.Link, {href:"/imprint",class:"col-lg-2 col-sm-12 imprint"}, Database.getInterface().imprint),


                ])
                
                
                
            ])
        ])        
    }
}


export default FooterLarge;