import m from 'mithril';
import Preloader from '../utils/preloader.class';
import Database from '../utils/database.class';


import Header from '../components/header.class';
import Footer from '../components/footer.class';
import migrantGallery from '../components/migrantGallery.class';
import $ from 'jquery';

class Home {

    constructor () {
        $("html").removeClass();
        $("html").addClass("home");
        
        if ( !Database.isLoaded() ) this.preloader = new Preloader();

        gtag('set', 'page_path', "home");
        gtag('event', 'page_view');
    }


   

    view () {
        if (!Database.isLoaded()) return; // make sure the DB is loaded
        
        return [
            m(Header),
            m(migrantGallery),
            m(Footer),
            
        ]
    }


}

export default Home;