import m from "mithril";
import FooterLarge from "../components/footerLarge.class";
import Preloader from '../utils/preloader.class';
import Database from '../utils/database.class';
import contentSection from "../components/contentSection.class";
import $ from 'jquery';
import aboutHeader from "../components/aboutHeader.class";


class About {

    constructor() {
        $("html").removeClass();
        $("html").addClass("about");

        if ( !Database.isLoaded() ) this.preloader = new Preloader();

        gtag('set', 'page_path', "about");
        gtag('event', 'page_view');
        

    }

    oncreate () {
        this.initMouse();
    }
    onupdate () { 
        this.initMouse();
    }
    initMouse () {
        $("#magicPointer").remove();
        let options = {
             "cursorOuter": "disable",
             "hoverEffect": "text-hover",
             "hoverItemMove": false,
             "defaultCursor": false,
             "outerWidth": 30,
             "outerHeight": 30
        };
        magicMouse(options);

    }


    view() {
        if (!Database.isLoaded()) return; // make sure the DB is loaded
        let about = Database.getAbout()[0];
        console.log ( Database.getAbout() );

        return [
            m(aboutHeader),
            
            
            m("div", {class:"trailer"}, [
                m("iframe",{width:"100%", height:"600px", src:"https://www.youtube.com/embed/llNfCYsQjpk", frameborder:"0", allow:"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowfullscreen:true})
            ]),

            m("div", {class:"headline"}, [
                m("span", m.trust(about.headline))
            ]),

            
            
            m("div", {class:"maincontent"}, [
                m(contentSection, {type:"about", content:about, part: "part1"}), 
                 

                // SCHOLARS
                m("section", {class:"scholars maincontent container"}, [
                    m("div", {class:"title"}, Database.getInterface().scholars_headline ),
                    Database.getScholars().map( (scholar,index) => {
                        if (scholar.id == 13 || scholar.id == 2 )return;
                        
                        return [
                            m("div", { class: "row scholar scholar-"+scholar.id}, [
                                m(m.route.Link, {href:"scholar/"+scholar.id, class:"col-lg-3 col-md-5 col-sm-12 image magic-hover", hovertext: Database.getInterface().more}, [
                                    m("img", {src:"images/scholars_about/"+scholar.image})
                                ]),
                                m("div", {class:"col-lg-9 col-md-7 col-sm-12 description"}, [
                                    m("div", {class:"name"}, scholar.name),
                                    m("div", {class:"description"}, m.trust(scholar.description)),
                                    m("div", {class:"quote_long"}, m.trust(scholar.short_cv))
                                ])
                            ])
                        ]
                    }),
                ]),
 


                // FILMMAKERS
                m("section", {class:"filmmakers maincontent container"}, [
                    m("div", {class:"title"}, Database.getInterface().filmmakers_headline ),
                    Database.getFilmmakers().map( (filmmaker,index) => {
                        return [ 
                            m("div", { class: "row scholar scholar-"+filmmaker.id}, [
                                m("div", {class:"col-lg-3 col-md-5 col-sm-12 image"}, [
                                    m("img", {src:"images/scholars_about/"+filmmaker.image})
                                ]),
                                m("div", {class:"col-lg-9 col-md-7 col-sm-12 description"}, [
                                    m("div", {class:"name"}, filmmaker.name),
                                    m("div", {class:"description"}, m.trust(filmmaker.description)),
                                    m("div", {class:"quote_long"}, m.trust(filmmaker.short_cv))
                                ])
                            ])
                        ]
                    }),
                ]),


                m(contentSection, {type:"about", content:about, part: "part2"}), 
                m(contentSection, {type:"about", content:about, part: "part3"}), 


                m("section", {class:"credits maincontent container"},[ 
                    m("div", {class:"title col-12"}, Database.getInterface().credits_headline),
                    m("div", {class:"description offset-lg-4 col-lg-8 col-sm-12"}, m.trust(about.credits)),                    
                ]),
            
            ]),

            
            
            m(FooterLarge)
            
        ]
    }
}

export default About;