import m from "mithril";



import Home from './classes/home.class';
import About from './classes/about.class';
import Migrant from './classes/migrant.class';
import Scholar from './classes/scholar.class';
import Imprint from './classes/imprint.class';

m.route.prefix = '?';
const mountNode = document.querySelector("#app");


//if(location.href.substr(0,5) !== 'https') location.href = 'https' + location.href.substr(4, location.href.length - 4)

const routes = {
  "/": Home,
  "/about": About,
  "/imprint": Imprint,
  "/migrant/:key": Migrant,
  "/scholar/:key": Scholar
};

m.route(mountNode, "/", routes);
