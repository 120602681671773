import m from 'mithril';
import Database from '../utils/database.class';
class SidebarMigrant  {
    constructor() {
        
    }

    view (vnode) {
     
        
        let next_story = ( parseInt(m.route.param("key")) ) >= Database.getMigrants().length ? 1 : parseInt(m.route.param("key"))+1 ;
        let prev_story = ( parseInt(m.route.param("key")) ) == 1 ? Database.getMigrants().length : parseInt(m.route.param("key"))-1 ;

      
         
        return m("sidebar", {class:"col-lg-1 col-md-12 col-sm-12"}, [
            m("div", {class:"links"}, [
                

                m(m.route.Link, {href:"/migrant/"+next_story, class:"left"}, [
                    m("img", {src: "images/Pfeil_next_story_01.png", class:"arrow left"})
                ], Database.getInterface().sidebar_nextstory),

                 
                m(m.route.Link, {href:"/migrant/"+prev_story, class:"left"}, [
                    m("img", {src: "images/previous_story.png", class:"arrow left"})
                ], Database.getInterface().sidebar_previousstory),

                m(m.route.Link, {href:"/", class:"left"}, [
                    m("img", {src: "images/allstories_migrant.svg", class:"arrow left all"})
                ], Database.getInterface().sidebar_allstories),
            ])
        ])        
    } 
}


export default SidebarMigrant;