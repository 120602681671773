import m from 'mithril';

let migrants = {};
let scholars = {};
let about = {};
let imprint = {};
let ui = {};
let filmmakers = {};
let migrantsLoaded = new Event("migrantsLoaded");
let scholarsLoaded = new Event("scholarsLoaded");
let interfaceLoaded = new Event("interfaceLoaded");
let aboutLoaded = new Event("aboutLoaded");
let imprintLoaded = new Event("imprintLoaded");
let filmmakersLoaded = new Event("filmmakersLoaded");

let allLoaded = false;
class Database {

    // returns if all databases are loaded
    static isLoaded() {
        return allLoaded;
    }

    // sets if the databases are loaded
    static setIsLoaded (loaded) {
        allLoaded = loaded;
    }

    static loadDatabase() {
       Database.loadMigrants();
    }

    static getFilmmakers () {
        return filmmakers;
    }
    static getMigrants () {
        return migrants;
    }
    static getScholars () {
        return scholars;
    }
    static getInterface () {
        return ui;
    }
    static getAbout () {
        return about;
    }

    static getImprint () {
        return imprint;
    }

    static loadMigrants () {

        m.request( {
            method: "GET",
            url: "../db/migrants.json"
        })
        .then ( (result) => {
            migrants = result.migrants;
            document.dispatchEvent(migrantsLoaded);
        })
    }

    static loadFilmmakers () {

        m.request( {
            method: "GET",
            url: "../db/filmmakers.json"
        })
        .then ( (result) => {
            filmmakers = result.filmmakers;
            document.dispatchEvent(filmmakersLoaded);
        })
    }

    static loadAbout () {

        m.request( {
            method: "GET",
            url: "../db/about.json"
        })
        .then ( (result) => {
            about = result.about; 
            document.dispatchEvent(aboutLoaded);
        })
    }


    static loadImprint () {

        m.request( {
            method: "GET",
            url: "../db/imprint.json"
        })
        .then ( (result) => {
            imprint = result.imprint; 
            document.dispatchEvent(imprintLoaded);
        })
    }

    static loadScholars () {

        m.request( {
            method: "GET",
            url: "../db/scholars.json"
        })
        .then ( (result) => {
            scholars = result.scholars;
            document.dispatchEvent(scholarsLoaded);
        })

    }

    static loadInterface () {

        m.request( {
            method: "GET",
            url: "../db/interface.json"
        })
        .then ( (result) => {
            ui = {
                ...ui,
                ...result
            }
            document.dispatchEvent(interfaceLoaded);
        })

    }

    
}

export default Database;